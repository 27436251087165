import { render, staticRenderFns } from "./oldIndex.vue?vue&type=template&id=ab4ae072&"
import script from "./oldIndex.vue?vue&type=script&lang=js&"
export * from "./oldIndex.vue?vue&type=script&lang=js&"
import style0 from "./oldIndex.vue?vue&type=style&index=0&id=ab4ae072&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports